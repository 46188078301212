.wallet-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 150px;
  overflow-x: hidden;
}

.wallet-content {
  flex: 1;
  width: 100%;
  min-height: calc(100vh - 60px - 200px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
}

.wallet-sidebar {
  width: 250px;
  background-color: #ffffff;
  padding: 1rem;
  height: calc(100vh - 150px);
  position: fixed;
  left: 0;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.05);
}

.sidebar-title {
  display: none;
}

.sidebar-buttons {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem;
  height: calc(100vh - 250px);
  position: relative;
}

.button-icon {
  font-size: 1.3rem;
  color: #333;
}

.sidebar-button {
  background-color: transparent;
  border: none;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.9rem;
  font-weight: normal;
  color: #333;
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: left;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.sidebar-button:hover {
  background-color: #f5f5f5;
}

.sidebar-buttons::after {
  content: '';
  flex: 1;
  min-height: calc(100vh - 300px);
}

.logout-button {
  background-color: #dc3545;
  color: white;
  border: none;
  width: calc(100% - 1rem);
  height: 40px;
  border-radius: 4px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.9rem;
  font-weight: normal;
  cursor: pointer;
  margin: 0.5rem;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
}

.logout-button .button-icon {
  color: white;
}

.wallet-main-content {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  margin-left: 250px;
}

@media screen and (max-width: 768px) {
  .wallet-page {
    padding-top: 120px;
    min-height: 100vh;
    overflow: hidden;
  }

  .wallet-content {
    flex: 1;
    height: calc(100vh - 120px);
    overflow: hidden;
    padding: 0;
  }

  .wallet-main-content {
    margin-left: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .wallet-iframe-container {
    position: relative;
    top: 21px;
    left: 0;
    width: 100%;
    height: calc(100vh - 120px);
    padding: 10px;
    margin-top: 0;
    background: #fff;
    border-radius: 0;
    box-shadow: none;
  }

  .wallet-iframe {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
    overflow: hidden;
    transform: scale(1);
    transform-origin: top center;
  }

  .wallet-sidebar {
    display: none;
  }

  .logout-button {
    position: relative;
    margin-top: 2rem;
  }
}

@media screen and (min-width: 769px) {
  .wallet-page {
    padding-top: 150px;
  }

  .wallet-content {
    display: flex;
    height: calc(100vh - 150px);
  }

  .wallet-main-content {
    flex: 1;
    margin-left: 250px;
    padding: 20px;
    position: relative;
  }

  .wallet-iframe-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20px;
  }

  .wallet-iframe {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  }
}