@tailwind utilities {
  .text-balance {
    text-wrap: balance;
  }
}

@font-face {
  font-family: "inter";
  src: url(../fonts/inter.ttf);
  font-weight: normal;
  font-style: bold;
}

/*Payment*/

.logo {
  top: 25px;
  position: relative;
}

.container-payment-history {
  position: relative;
  top: 50px;
  background-color: #eceded;
  width: 100%;
  height: calc(105vh - 80px);
  border-top-left-radius: 70px;
  border-top-right-radius: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3); 
}

.container-payment-text-history {
  position: relative;
  top: 50px;
  background-color: #fffafa;
  width: 85%;
  height: 150px;
  border-radius: 40px;
}

.text-payment-history {
  font-family: "inter";
  font-weight: 600;
  font-size: 20px;
  color: #190e5f;
  position: relative;
  width: 240px;
  display: flex;
  text-align: center;
  top: 40px;
}

.container-text-payment-history{
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.container-check{
  position: relative;
  top: 25px;
}

.text-bold{
  font-family: "inter";
  font-weight: 600;
  font-size: 20px;
  color: #6AB140;
  position: relative;
  left: 5px;
}
.text-bold1{
  font-family: "inter";
  font-weight: 600;
  font-size: 20px;
  color: #FF0000;
  position: relative;
  left: 5px;
}

.text-adjust{
  font-family: Arial, Helvetica, sans-serif;
  font-style: initial;
  position: relative;
  left: 5px;
  top: 1px;
  font-weight: 100;
}
.text-adjust1{
  font-family: Arial, Helvetica, sans-serif;
  font-style: initial;
  position: relative;
  right: 25px;
  top: 25px;
  font-weight: 100;
}

