@tailwind utilities {
    .text-balance {
      text-wrap: balance;
    }
  }

  @font-face {
  font-family:'inter' ;
  src: url(../fonts/inter.ttf);
  font-weight: normal; 
    font-style: bold; 
}

  /*Payment*/
  
  .logo{
    top: 25px;
    position: relative;
  }
  
  
  .container-payment {
    position: relative;
    top: 50px;
    background-color: #ECEDED;
    width: 100%;
    height: calc(105vh - 80px);
    border-top-left-radius: 70px;
    border-top-right-radius: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center; 
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3); 
}
  
  .text-sesion{
    font-family: 'inter';
    font-weight: 600;
    font-size: 27px;
  }
  
  .container-payment-text{
    position: relative;
    top: 0px; 
    background-color: #FFFAFA;
    width: 90%;
    height: 155px; 
    border-radius: 40px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3); 
  }
  .container-payment-text1{
    position: relative;
    top: 20px; 
    background-color: #FFFAFA;
    width: 90%;
    height: 414px; 
    border-radius: 40px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3); 
  }

  .container-text{
    position: relative;
    text-align: start;
    top: 30px;
    left: 40px;
  }
  .container-text1{
    text-align: end;
    position: relative;
    right: 40px;
    top: 7px;

  }
  .container-text2{
    text-align: end;
    position: relative;
    right: 40px;
    top: 7px;

  }

  .text-payment{
    font-family: 'inter';
    font-weight: 600;
    font-size: 20px;
    color: #190E5F;
    position: relative;
  }

  .text-payment1{
    font-family: 'inter';
    font-weight: 600;
    font-size: 20px;
    color: #190E5F;
    position: relative;
  }

  .mount-text{
    font-family: 'inter';
    font-weight: 600;
    font-size: 50px;
    color: #5CAF2A;
    top: 10px;
    position: relative;
  }