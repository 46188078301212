.container-menu {
    position: relative;
    top: 50px;
    background-color: #eceded;
    width: 20%;
    height: calc(105vh - 80px);
    border-top-left-radius: 0px;
    border-top-right-radius: 38px;
    border-bottom-left-radius: 60;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3); 
  }

.menu-content {
  padding-top: 80px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.container-button {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
  width: 100%;
  max-width: 300px;
  padding: 0 1rem;
  margin: 0 auto;
}

.transactions,
.logout {
  width: 100%;
  padding: 1rem;
  border-radius: 8px;
  border: none;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.transactions {
  background-color: #64C1D7;
  color: white;
}

.logout {
  background-color: #190E5F;
  color: white;
}

.transactions:hover,
.logout:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Estilos responsive */
@media screen and (max-width: 767px) {
  .menu-content {
    padding-top: 60px;
  }
  
  .container-button {
    padding: 0 2rem;
  }

  /* Asegurarse que el menú hamburguesa sea visible */
  .hamburger-menu {
    display: block !important;
    position: fixed;
    top: 1rem;
    right: 1rem;
    z-index: 1000;
  }

  /* Ajustar el logo para móviles */
  .logo {
    margin-top: 1rem;
    text-align: center;
  }

  .logo img {
    width: 120px;
    height: auto;
  }
}